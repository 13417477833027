
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import TripEnum from '@/enums/rides/RolesEnum';
import AtomText from '@/components/atoms/AtomText.vue';
// import AtomNotification from '@/components/atoms/AtomNotification.vue';
import AtomRoleSelector from '@/components/atoms/AtomRoleSelector.vue';

export default Vue.extend({
  name: 'OrganismRoleSelection',
  data: () => ({
    TripEnum,
  }),
  components: {
    AtomRoleSelector,
    // AtomNotification,
    AtomText,
  },
  methods: {
    ...mapActions('rideIntent', ['selectRole']),
  },
  computed: {
    ...mapGetters('rideIntent', [
      'getSelectedRole',
      'isPoolCarRequest',
      'isEditMode',
      'isRecurrenceTrip',
      'isSequenceTrip',
      'isOutwardInThePast',
    ]),
  },
});
